import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Assetimg from "../assets/imgs/Illuma.png";
import { useSelector } from "react-redux";
import { formatNumber } from "../utils";
function Hero() {
  const { totalSupply, tokenSold } = useSelector((state: any) => state?.wallet);

  return (
    <div className="hero pt-8 pt-md-10">
      <Container className="pb-10">
        <Row className="align-items-center">
          <Col md="7">
            <h2 className="heading mb-0">
              Welcome to <br /> Platfair NFT Minting
            </h2>
            <div className="mt-5 text-center text-md-start">
            </div>
            <div className="d-flex align-items-center justify-content-between justify-content-md-start mt-5">
              <div className="me-0 me-md-5">
                <h2 className="token">
                  2000 
                </h2>
                <h5 className="desc">Premium NFTs</h5>
              </div>
              <div>
                <h2 className="token">8000</h2>
                <h5 className="desc">Standard NFTs</h5>
              </div>
            </div>
          </Col>
          <Col md="5">
            <img
              src={Assetimg}
              alt="hero"
              draggable="false"
              className="img"
              width = "400" height = "400"
              
              
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hero;
