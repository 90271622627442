import React from "react";
import { Container } from "reactstrap";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <h2 className="logo mb-0 text-center"></h2>
        <div className="text-center">
          <small> Please be advised that the Referral system used by Platfair is a privileged access service reserved strictly for NFT owners. 
          Only those who have purchased and possess a valid Platfair NFT will be allowed to use it. 
          Any unauthorized attempts to use the referral system will not work.</small>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
