import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  getExchangeRate,
  getReferralBonus,
  getSaleContractTokenBalance,
  getTokenBalance,
  getTotalSale,
  getTotalSupply,
  getUsdtBalance,
  getUsdtTokenAllowance,
} from "../utils";
import { useDispatch } from "react-redux";
import {
  setExchangeRate,
  setReferralReward,
  setTokenAllowance,
  setTokenBalance,
  setTokenSold,
  setTokenToSale,
  setTotalSupply,
  setUsdtBalance,
} from "../store/wallet/wallet.slice";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
// import { WalletActionButton } from "@tronweb3/tronwallet-adapter-react-ui";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import Platfairlogo from "../assets/imgs/logo_platfair_hd.png";

function Header(props: any) {
  const [scrollY, setScrollY] = useState(0); // Initial background color

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const { address } = useSelector((state: any) => state?.wallet);

  const { address } = useWallet();

  const handleScroll = () => {
    setScrollY(window?.scrollY);
  };

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (address) {
      getUsdtBalance(address).then((res: any) => {
        dispatch(setUsdtBalance(res));
      });

      getTokenBalance(address).then((res: any) => {
        dispatch(setTokenBalance(res));
      });

      getUsdtTokenAllowance(address).then((res: any) => {
        dispatch(setTokenAllowance(res));
      });

      getExchangeRate().then((res: any) => {
        dispatch(setExchangeRate(res));
      });
      getTotalSupply().then((res: any) => {
        dispatch(setTotalSupply(res));
      });
      getTotalSale().then((res: any) => {
        dispatch(setTokenSold(res));
      });

      getReferralBonus(address).then((res: any) => {
        dispatch(setReferralReward(res));
      });

      getSaleContractTokenBalance().then((res: any) => {
        dispatch(setTokenToSale(res));
      });
    }
  }, [address, dispatch]);

  return (
    <div
      className="header"
      style={{
        backgroundColor: scrollY > 90 ? "#000000" : "#261faa",
      }}
    >
      <Container className="d-flex justify-content-between px-3 px-md-5" fluid>
        <div className="d-flex align-items-center">
          <h2
            className="logo me-3 me-md-8 mb-0"
            onClick={() => {
              navigate("/");
            }}
          >
             <a href="https://platfair.ai/"><img
              src={Platfairlogo}
              alt="hero"
              draggable="false"
              className="img-responsive"
              
            /></a>
          </h2>
        </div>
        <WalletModalProvider>
          <WalletActionButton
            className="btn btn-primary"
            // style={{ width: "100%" }}
          ></WalletActionButton>
        </WalletModalProvider>      </Container>
    </div>
  );
}

export default Header;
